import { useQuery } from '@tanstack/react-query';
import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { useProtocolDataContext } from '../useProtocolDataContext';

export interface IAlmPoolAprRequestParams {
  vaultAddress?: string;
  chainId: number;
}

export interface IAlmPoolAprResponse {
  apr: {
    apr: number;
    message?: string;
  },
  vaultAddress: string;
}

export const getAlmPoolsApr = async ({ vaultAddress, chainId }: IAlmPoolAprRequestParams) => {
  try {
    const url = `https://api.steer.finance/pool/fee-apr?address=${vaultAddress}&chain=${chainId}&interval=604800`;
    const result = await fetch(url);
    const json: IAlmPoolAprResponse = await result.json();
    return json;
  } catch (e) {
    return null;
  }
};

const INITIAL_DATA: IAlmPoolAprResponse = {
  apr: { apr: 0 },
  vaultAddress: '',
}

export const useAlmPoolApr = ({ vaultAddress }: { vaultAddress?: string; }) => {
  const { currentChainId } = useProtocolDataContext();

  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.ALM_POOL_APR, currentChainId, vaultAddress],
    queryFn: () => getAlmPoolsApr({
      chainId: currentChainId,
      vaultAddress,
    }),
    enabled: Boolean(currentChainId && vaultAddress),
    refetchInterval: POLLING_INTERVAL,
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  };
};
