import BigNumber from 'bignumber.js';
import { EControlPositionType, EPositionType, IEnrichedUniswapPosition } from '../../../types/uniswapTokens';
import { usePoolDataQuery } from '../../../hooks/yldr/usePoolDataQuery';
import { calculateEstimateYearlyFees } from '../../../utils/calculateEstimateYearlyFees';
import { getBorrowApyByLeverage } from '../../../utils/getBorrowApyByLeverage';
import { useGlobalRevenueFeesQuery } from '../../../hooks/yldr/useGlobalRevenueFeesQuery';
import { EMarketName } from '../../../ui-config/marketsConfig';
import { calculateAutoCompoundValues } from '../../../utils/calculateAutoCompoundValues';
import { useRootStore } from '../../../store/root';
import { findTokenInReserves } from '../../../utils/findTokenInReserves';
import { useAppDataContext } from '../../../hooks/app-data-provider/useAppDataProvider';
import { useAlmPoolApr } from '../../../hooks/yldr/useAlmPoolApr';

export interface IUseEstAprValuesParams {
  position?: IEnrichedUniswapPosition;
  autoCompoundInitialized?: boolean;
  autoCompoundValue?: number;
  poolEstApr?: number;
}

export const useEstAprValues = ({ position, autoCompoundInitialized, autoCompoundValue }: IUseEstAprValuesParams) => {
  const currentNetworkConfig = useRootStore((store) => store.currentNetworkConfig);
  const { reserves, loading: isLoadingAppDataContext } = useAppDataContext();
  const { data: estimatedUsdFees, isLoading: isEstimatedUsdFees } = usePoolDataQuery({
    marketId: position?.marketId,
    token0: position?.token0.underlyingAsset,
    token1: position?.token1.underlyingAsset,
    feeAmount: position?.feeAmount,
    enabled: position?.type !== EPositionType.Pool,
  });

  const { data: globalRevenueFees, isLoading: isLoadingRevenueFee } = useGlobalRevenueFeesQuery();
  const { data: almPoolsAprData } = useAlmPoolApr({ vaultAddress: position?.vault });

  const netWorthUsd = BigNumber(position?.token0.netWorthUsd || 0)
    .plus(position?.token1.netWorthUsd || 0)
    .toNumber();

  const getEstApr = () => {
    if (!position) {
      return [0, 0, 0];
    }

    const estimateYearlyFees = calculateEstimateYearlyFees(
      estimatedUsdFees,
      BigNumber(position.liquidity.toString()),
    );

    // Pools
    if (position.type === EPositionType.Pool) {
      return [position.maxApr || 0, 0, 0]
    }

    // Alm Positions
    if (position.controlType === EControlPositionType.AlmPosition) {
      const estApr = BigNumber(
        BigNumber(position.marketReferenceCurrencyValue || 0).multipliedBy(almPoolsAprData?.apr?.apr || 0)
          .minus(
            BigNumber(position.marketReferenceCurrencyDebt || 0).multipliedBy(position.borrowApy || 0)
          )
      ).div(
        BigNumber(position.marketReferenceCurrencyValue ||  0).minus(position.marketReferenceCurrencyDebt || 0)
      ).div(100).toNumber();

      const borrowApy = position.marketReferenceCurrencyDebt
        ? BigNumber(position.marketReferenceCurrencyDebt || 0)
          .multipliedBy(position.borrowApy || 0)
          .div(netWorthUsd)
          .toNumber()
        : 0;

      return [estApr, borrowApy, 0];
    }

    // Positions
    if (estimateYearlyFees) {

      // Leveraged position
      if (position.type === EPositionType.LeveragedPosition) {

        const borrowApy = position.marketReferenceCurrencyDebt
          ? BigNumber(position.marketReferenceCurrencyDebt || 0)
            .multipliedBy(position.borrowApy || 0)
            .div(netWorthUsd)
            .toNumber()
          : 0;

        const estApr = position.positionInRange
          ? estimateYearlyFees.div(netWorthUsd).toNumber()
          : 0;

        const revenueFee = BigNumber(estApr)
          .multipliedBy(BigNumber(position.revenueFeePercent || 0))
          .div(10000)
          .toNumber();

        return [estApr, borrowApy, revenueFee];
      }

      // Positions to leverage
      if (position.type === EPositionType.Position) {
        const estAprNoLeverage = position.positionInRange
          ? estimateYearlyFees.div(netWorthUsd)
          : BigNumber(0);

        const leverage0 = position.token0.availableLeverage;
        const leverage1 = position.token1.availableLeverage;

        const estApr0 = position.positionInRange
          ? estAprNoLeverage.multipliedBy(leverage0)
          : BigNumber(0);
        const estApr1 = position.positionInRange
          ? estAprNoLeverage.multipliedBy(leverage1)
          : BigNumber(0);

        const tokenA = findTokenInReserves(reserves, position.token0.underlyingAsset);
        const tokenB = findTokenInReserves(reserves, position.token1.underlyingAsset);

        const borrowApy0 = BigNumber(getBorrowApyByLeverage(leverage0, position, tokenA)).multipliedBy(leverage0 - 1).dividedBy(100);
        const borrowApy1 = BigNumber(getBorrowApyByLeverage(leverage1, position, tokenB)).multipliedBy(leverage1 - 1).dividedBy(100);
        const globalRevenueFee = globalRevenueFees.find((item) => {
          return item.marketName === EMarketName.Uniswap;
        })?.revenueFee;
        const revenueFeePercent0 = BigNumber(globalRevenueFee || 0).multipliedBy(leverage0 - 1).dividedBy(leverage0);
        const revenueFeePercent1 = BigNumber(globalRevenueFee || 0).multipliedBy(leverage1 - 1).dividedBy(leverage1);

        const revenueFee0 = estApr0.multipliedBy(revenueFeePercent0).dividedBy(10000);
        const revenueFee1 = estApr1.multipliedBy(revenueFeePercent1).dividedBy(10000);

        const netApr0 = estApr0.minus(borrowApy0).minus(revenueFee0);
        const netApr1 = estApr1.minus(borrowApy1).minus(revenueFee1);

        const maxNetApr = BigNumber.max(netApr0, netApr1, estAprNoLeverage);

        if (maxNetApr.isEqualTo(netApr0)) {
          return [estApr0.toNumber(), borrowApy0.toNumber(), revenueFee0.toNumber()];
        } else if (maxNetApr.isEqualTo(netApr1)) {
          return [estApr1.toNumber(), borrowApy1.toNumber(), revenueFee1.toNumber()];
        } else {
          return [estAprNoLeverage.toNumber(), 0, 0];
        }
      }
    }

    return [0, 0, 0];
  }

  const [estApr, borrowApy, revenueFee] = getEstApr();

  const autoCompoundValues = calculateAutoCompoundValues({
    positionApr: estApr - revenueFee,
    borrowed: position?.marketReferenceCurrencyDebt,
    positionValue: position?.marketReferenceCurrencyValue,
    compoundGasCost: currentNetworkConfig.compoundGasCost,
    compoundCostCap: Number(autoCompoundValue),
  });

  const estNetApr = estApr - borrowApy - revenueFee;

  const currentEstApr = autoCompoundInitialized && position?.positionInRange
    ? autoCompoundValues.apr / 100 + borrowApy + revenueFee
    : estApr;

  const currentEstNetApr = autoCompoundInitialized && position?.positionInRange
    ? autoCompoundValues.apr / 100
    : estNetApr;

  const isLoading = isEstimatedUsdFees || isLoadingRevenueFee || isLoadingAppDataContext;

  return {
    estNetApr: currentEstNetApr,
    estApr: currentEstApr,
    netWorthUsd,
    borrowApy,
    revenueFee,
    isLoading,
  }
}
